.cv-title h2 {
	&:before, &:after {
		display: none;
	}
}

.project.thumb {
	&.big, &.medium, &.tall, &.small {
		width: 100%;
		.inner {
			height: auto;
			padding-bottom: 100px;
		}
		&.paper {
			h1 {
				font-size: 1.2em;
				letter-spacing: 0;
			}
		}
	}
	&.marmalaid .inner {
		.browser {
			width: 300px;
			bottom: -40px;
			right: auto;
			left: 330px;
		}
	}
	
	&.swapabilities .inner {
		img {
			width: 300px;
			bottom: -40px;
			right: -200px;
		}
	}
}