.email {
	padding: 10px 0;
	display: block;
	color: white;
	text-align: center;
	text-decoration: none;
}


.cv-title {
	padding-top: 60px;
	h2 {
		text-align: center;
		position: relative;
		&:before, &:after {
			content: " ";
			display: block;
			height: 8px;
			width: 100px;
			background-color: white;
			position: absolute;
			top: .55em;
		}
		&:before {
			left: 0;
		}
		&:after {
			right: 0;
		}
	}
	
}

.cv {
	position: relative;
	//max-width: 700px;
	

	h3 {
		font-size: 1.6em;
		margin-bottom: 8px;
		font-weight: 600;
	}
	h4 {
		letter-spacing: 0;
		color: $text;
		font-size: 1.2em;
		font-weight: 600;
		margin-bottom: 4px;
		span {
			font-size: 0.7em;
			color: rgba($background, 0.7);
			//font-weight: 400;
		}
	}
	
	p {
		border-left: 3px solid $text;
		padding-left: 12px;
		line-height: 1.6em;
	}
	
	.button {
		margin-bottom: 30px;
		//position: absolute;
		//right: 0;
		//top: 80px;
	}
	
	.skills, .work, .education {
		margin-bottom: 30px;
		h4 {
			color: $primary_dark;
		}
		p {
			border-left-color: $primary_dark;
		}
	}
	
	/*.skills {
		h4 {
			color: $teal;
		}
		p {
			border-left-color: $teal;
			margin-bottom: 0;
		}
	}
	
	.work {
		h4 {
			color: $red;
		}
		p {
			border-left-color: $red;
		}
	}
	
	.education {
		h4 {
			color: $green;
		}
		p {
			border-left-color: $green;
		}
	}*/
	
	
}