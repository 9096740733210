.front {
	padding-top: 120px;
}

#nav {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	margin-top: 50px;
	.row {
		
	}
	
	.site-title {
		display: block;
		text-align: center;
		//font-family: 'Abel', sans-serif;
		font-size: 2em;
		font-weight: 200;
		color: $text;
		//padding-top: 3px;
		line-height: 2em;
		height: 2em;
		
		
		
		
		
	}
	.resume {
		
	}
	.contact {
		float: right;
	}
	.resume, .contact {
		margin-top: 18px;
		font-size: 0.8em;
		height: 2em;
		line-height: 2em;
		display: inline-block;
		color: rgba(#FFF, 0.6);
		padding: 0 10px;
	}
	a {
		text-decoration: none;
		text-transform: uppercase;
		
		border: 1px solid transparent;
		//border-radius: 3px;
		&:hover {
			border-color: white;
			border-color: rgba(#FFF, 0.7);
			background-color: rgba(#FFF, 0.1);
		}
	}
}

footer {
	padding-top: 80px;
	text-align: center;
	color: $faded;
}