.unslider{overflow:auto;margin:0;padding:0}.unslider-wrap{position:relative}.unslider-wrap.unslider-carousel>li{float:left}.unslider-vertical>ul{height:100%}.unslider-vertical li{float:none;width:100%}.unslider-fade{position:relative}.unslider-fade .unslider-wrap li{position:absolute;left:0;top:0;right:0;z-index:8}.unslider-fade .unslider-wrap li.unslider-active{z-index:10}.unslider li,.unslider ol,.unslider ul{list-style:none;margin:0;padding:0;border:none}.unslider-arrow{position:absolute;left:20px;z-index:2;cursor:pointer}.unslider-arrow.next{left:auto;right:20px}

.unslider {
	position: relative;
}

.project .unslider-nav {
	position: absolute;
	bottom: 5px;
	left: 0;
	right: 0;
	z-index: 10;
	ol li {
		width: 10px;
		height: 10px;
		background: #FFF;
		border-radius: 99px;
		opacity: 0.3;
		&.unslider-active {
			opacity: 0.8;
		}
	}
}

.no-js .browser-multi {
	
	ul, li {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	
	li {
		display: none;
		&:first-child { 
			display: block;
		}
	}
	
}

.project.marmalaid, .project.nawmp, .project.timreview {
	.unslider-nav ol li {
		background-color: $background;
		border-color: $background;
	}
}