

body.project {
	.post-title {
		position: relative;
		padding-top: 20px;
		&:before {
			content: " ";
			display: block;
			height: 8px;
			width: 100px;
			background-color: white;
			position: absolute;
			top: 0;
		}
	}
	
	.post-byline {
		font-size: 1.3em;
		font-weight: 600;
		background-color: white;
		color: #5a6060;
		display: inline-block;
		padding: 3px 5px;
	}
	
	.post-header {
		background-image: url("/img/other/header-bg.jpg");
		background-position: top center;
		background-size: cover;
		padding-top: 200px;
		padding-bottom: 200px;
	}
}

body.project.nawmp {
	.post-header {
		background-image: url("/img/other/header-nawmp.jpg");
	}
}

body.project.timreview {
	.post-header {
		background-image: url("/img/other/header-timreview.jpg");
	}
}

body.project.marmalaid {
	.post-header {
		background-image: url("/img/other/header-marmalaid.jpg");
	}
}

body.project.casualmakers {
	.post-header {
		background-image: url("/img/other/header-3dprinting.jpg");
	}
}

body.project.asteroidwars {
	.post-header {
		background-image: url("/img/other/header-asteroid.jpg");
	}
}

body.project.clarificationrequests {
	.post-header {
		background-image: url("/img/other/header-clarificationrequests.jpg");
	}
}

body.project.swapabilities {
	.post-header {
		background-image: url("/img/other/header-swapabilities.jpg");
	}
}

body.project.hub3d {
	.post-header {
		background-image: url("/img/other/header-3dhub.jpg");
	}
}

body.project.thingiversebarriers {
	.post-header {
		background-image: url("/img/other/header-thingiverse.jpg");
	}
}

.pull-way-up {
	margin-top: -150px;
}

.pull-up {
	margin-top: -75px;
}

.pull-a-bit-up {
	margin-top: -35px;
}

.pull-down {
	margin-top: 75px;
}

.pull-way-down {
	margin-top: 150px;
}

.pull-a-bit-down {
	margin-top: 35px;
}

.lead {
	font-size: 1.6em;
}

.readable {
	background-color: white;
	color: $background;
	padding: 50px;
	&.one-third {
		padding: 25px;
	}
}

.deets {
	ul {
		padding: 1.5em 0;
		margin: 0;
	}
	li {
		margin: 0;
		list-style: none;
		padding: .5em 0;
		font-weight: 200;
		border-bottom: 1px solid rgba(#FFF, .5);
		&:first-child {
			border-top: 2px solid rgba(#FFF, .8);
		}
	}
	h3 {
		letter-spacing: 0;
		font-size: 1.3em;
		font-weight: 600;
		margin: 0;
	}
	.button {
		margin-bottom: 35px;
	}
	h4 {
		letter-spacing: 0;
		font-size: 1em;
		font-weight: 600;
		margin: 0;
	}
	em {
		color: $background;
		font-style: normal;
		font-weight: 600;
	}
	.readable {
		padding: 30px;
	}
}

.readable.deets {
	
	li {
		border-bottom: 1px solid rgba(#000, .2);
		&:first-child {
			border-top: 2px solid rgba(#000, .5);
		}
	}
	
}
