
#nav {
	margin-top: 35px;
	line-height: .4em;
	.site-title {
		font-size: 1.6em;
	}
	.contact {
		float: none;
	}
	.resume, .contact {
		display: none;
	}
}


body.project {
	
	.post-header {
		padding-top: 120px;
		
	}
	.post-title {
		font-size: 2em;
	}
	
	.lead {
	    font-size: 1.2em;
	}
	.readable {
		padding: 15px;
	}
}

.nextPrev {
	.inner {
		height: auto;
	}
	.columns {
		margin-top: 15px;
	}
}