.project.thumb {
	float:left;
	
	h1 {
		line-height: 0.9em;
		margin-bottom: 5px;
	}
	h2 {
		font-size: 1em;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 2px;
	}
	.browser {
		width: 400px;
		position: absolute;
		padding-top: 12px;
	}
	
	&.reverse {
		text-align: right;
	}
	
	
	
	
	&.big {
		width: 100%;
	}
	&.medium {
		width: 66.6666666667%;
	}
	&.tall {
		width: 33.3333333333%;
		height: 640px;
	}
	&.small {
		width: 33.3333333333%;
		&.paper {
			h1 {
				font-size: 1.4em;
				letter-spacing: 0;
			}
		}
	}
	
	&.paper {
		h1 {
			font-size: 2em;
			line-height: 1.1em;
		}
	}
	
	.inner {
		height: 300px;
		margin: 10px;
		padding: 30px;
		position: relative;
		overflow: hidden;
	}
	
	.button {
		margin-bottom: 0;
		position: absolute;
		bottom: 30px;
	}
	
	

	
}

.nextPrev {
	.inner {
		
		display: block;
		height: 180px;
		padding: 45px 30px;
		color: inherit;
		text-decoration: none;
		transition: padding .2s;
	}
	.prev .inner {
		&:after {
			content: "\e761";
			font-family: 'Linearicons';
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			font-size: 40px;
			position: absolute;
			left: 7px;
			top: 65px;
			opacity: 0;
			transition: opacity .2s;
		}
		&:hover {
			padding-right: 10px;
			padding-left: 50px;
			&:after {
				opacity: 1;
			}
		}
	}
	
	.next .inner {
		&:after {
			content: "\e762";
			font-family: 'Linearicons';
			speak: none;
			font-style: normal;
			font-weight: normal;
			font-variant: normal;
			text-transform: none;
			line-height: 1;
			font-size: 40px;
			position: absolute;
			right: 7px;
			top: 65px;
			opacity: 0;
			transition: opacity .2s;
		}
		&:hover {
			padding-left: 10px;
			padding-right: 50px;
			&:after {
				opacity: 1;
			}
		}
	}
	
	p {
		margin-bottom: 0;
	}
	.indicator {
		margin-top: 15px;
		text-transform: uppercase;
		font-weight: 600;
		letter-spacing: 2px;
		font-size: 0.8em;
	}
	.title {
		font-weight: 900;
		letter-spacing: -1px;
		font-size: 2em;
		line-height: 1em;
	}
	.paper {
		.indicator {
			margin-top: 0;
		}
		.title {
			font-size: 1.4em;
			letter-spacing: 0;
		}
	}
	.next {
		text-align: right;
		position: relative;
	}
	
	.prev {
		text-align: left;
		position: relative;
	}
	
}



.project.thumb, .nextPrev .project {
	
	&.casualmakers .inner {
		//background: #93c6d4;
		//background: linear-gradient(to right, rgba(#93c6d4, 1) 0%,rgba(#4fafc8, 1) 100%);
		background: #70e0aa url('/img/other/casualmakers_thumb.jpg') no-repeat center center;
		background-size: cover;
	}
	
	&.marmalaid .inner {
		background: #ffb952;
		background: linear-gradient(to right, rgba(#ffb952, 1) 0%,rgba(#d88d46, 1) 100%);
		.browser {
			opacity: 0.95;
			bottom: -40px;
			right: -70px;
		}
	}
	
	&.nawmp .inner {
		//background: linear-gradient(to right, rgba(#5fbe86, 1) 0%,rgba(#70e0aa, 1) 100%);
		background: #70e0aa url('/img/web/jpg/nawmp_thumb.jpg') no-repeat center center;
		background-size: cover;
	}
	
	&.asteroidwars .inner {
		background: #6d549f url('/img/web/png/asteroidwars_thumb.png') no-repeat center center;
		background-size: cover;
	}
	
	&.thingiversebarriers .inner {
		background: #3db5f2;
		background: linear-gradient(to right, rgba(#3db5f2, 1) 0%,rgba(#4085c0, 1) 100%);
	}
	
	&.swapabilities .inner {
		background: #c0ea98;
		background: linear-gradient(to right, rgba(#a6d578, 1) 0%,rgba(#d3e87d, 1) 100%);
		h1, h2 {
			max-width: 70%;
		}
		img {
			position: absolute;
			width: 400px;
			left: 70%;
			top: 15px;
		}
	}
	
	&.hub3d .inner {
		background: #373ae1;
		background: url('/img/other/3dhub_thumb.jpg') no-repeat center center;
		background-size: cover;
	}
	
	&.clarificationrequests .inner {
		background: #e6b58a;
	}
	
	&.timreview .inner {
		//background: #ff5252;
		background: #ff5252 url('/img/web/jpg/timreview_thumb.jpg') no-repeat center center;
		background-size: cover;
		//background: linear-gradient(to right, rgba(#ff5252, 1) 0%,rgba(#d84656, 1) 100%);
	}
	
}