


//COLORS
$background: #1e1f20;
$text: #FFF;

$faded: mix($text, $background, 50);

$pink: #F080B2;
$yellow: #EEBB3F;
$teal: #40C1BF;
$orange: #EB6E27;
$green: #8CC345;

$green:             #41c67a;
$red:               #E25440;
$slate:             #3D4260;

$primary: #33C3F0;
$primary_dark: #1D6C8B;